@font-face {
    font-family: 'GT Walsheim Pro Bold';
    src: url('./assets/fonts/gtwalsheimpro-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/gtwalsheimpro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro Regular';
    src: url('./assets/fonts/GTWalsheimPro-Regular.woff2') format('woff2'),
    url('./assets/fonts/GTWalsheimPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'GT Walsheim Pro Regular';
    font-style: normal;
}